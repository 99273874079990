<template>
  <div>
    <!--    <el-radio-group v-for='item of typeList' @change='serchs(item.id)' disabled='true' v-model='labelPosition' size='small'>-->
    <!--      <el-radio-button style='margin-right: 10px;' :label='item.id'>{{ item.value }}</el-radio-button>-->
    <!--    </el-radio-group>-->

    <el-button type="primary" icon="el-icon-plus" @click="userAddData"
      >新增</el-button
    >
    <div style="margin: 10px;"></div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        header-align="center"
        align="center"
        width="60"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        prop="picPath"
        header-align="center"
        align="center"
        label="图片"
      >
        <template slot-scope="scope">
          <img style="width:100px; height: 50px;" :src="scope.row.picPath" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="名称"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="createDatetime"
        header-align="center"
        align="center"
        label="发布时间"
      >
      </el-table-column>
      <el-table-column
        prop="viewsNumber"
        header-align="center"
        align="center"
        width="80"
        label="浏览次数"
      >
      </el-table-column>
      <el-table-column
        prop="sortOrder"
        header-align="center"
        align="center"
        label="排序"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-bottom"
            size="small"
            @click="handleSort(scope.row.id, 'down')"
          ></el-button>
          <el-button
            icon="el-icon-top"
            size="small"
            @click="handleSort(scope.row.id, 'up')"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        header-align="center"
        align="center"
        width="240"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="handleEdit(scope.row.id)"
            >编辑
          </el-button>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row.id)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="size"
      layout="total, prev, pager, next, jumper"
      :total="recordsTotal"
      style="margin-top: 10px"
    >
    </el-pagination>
    <div style="margin-top: 10px; height: 32px; width: 100%"></div>
    <el-dialog
      :title="this.title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      top="5vh"
      width="800px"
      @close="close('formName')"
    >
      <el-form :model="form" :rules="rules" ref="formName">
        <el-form-item
          label="轮播图："
          style="width: 100%;
    display: inline-block;
    margin: 14px -4px;
}"
          prop="picPath"
        >
          <el-upload
            action=""
            ref="upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            clearFiles
            class="formInput"
            style="width: 79.8%;margin-bottom: -15px;margin-top: -24px;"
            :file-list="imgList"
            :limit="1"
            :before-upload="imgUpload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
            <img class="images" width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称：" class="form" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入名称"
                class="formInput"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="文章出处：" class="form" prop="articleSource">
              <el-input
                v-model="form.articleSource"
                placeholder="请输入文章出处"
                class="formInput"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="作者：" class="form" prop="author">
              <el-input
                v-model="form.author"
                placeholder="作者"
                class="formInput"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="发布时间：" class="form" prop="releaseTime">
              <el-date-picker
                v-model="form.releaseTime"
                type="datetime"
                placeholder="请输入发布时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 67%"
                class="formInput"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="内容：" style="margin-left: 20px" prop="content">
          <vue-editor
            v-model="form.content"
            style="width: 88%;
            margin-left: 55px;
            margin-top: 10px;"
            class="formInput"
            :editor-toolbar="customToolbar"
          ></vue-editor>
        </el-form-item>

        <el-form-item
          style="display: flex;flex-direction: row;justify-content: flex-end;margin: 10px 0;"
        >
          <el-button type="primary" @click="formSave('formName')"
            >确 定
          </el-button>
          <el-button @click="formCancel('formName')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  rotatePicturesSoet,
  rotatePicturesDetail,
  rotatePicturesDelete,
  uploadFile,
  webServerHttpUrl,
  messageInfoupdate,
  messageInfoSave,
  rotateSort
} from '@/api/rotationMap'
import { VueEditor } from 'vue2-editor'
import { rotateList } from '../../api/rotationMap'
export default {
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        // 富文本框配置文件
        ['bold', 'italic', 'color', 'background', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video', 'code-block', 'code', 'link'],
        ['clean']
      ],
      web: null, // 图片路径
      imgList: [], // 图片数据
      dialogImageUrl: '', // 图片显示的路径
      title: null, // 表单弹窗标题
      tableData: [],
      typeList: [],
      labelPosition: 1,
      recordsTotal: null, // 数据总数
      pageInfo: {},
      dialogFormVisible: false, // 表单弹框开关
      dialogVisible: false,
      folder: {
        folder: 'rotatePictures'
      },
        current: 1,
        size: 10,
      params:{
        name:''
      },
      form: {
        articleSource: null,
        author: null,
        content: null,
        picPath: null,
        name: null,
        id: null,
        releaseTime: null,
        viewsNumber: null
      },
      rules: {
        picPath: [
          { required: true, message: '请输选择轮播图', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        articleSource: [
          { required: true, message: '请输入文章出处', trigger: 'blur' }
        ],
        author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
        releaseTime: [
          { required: true, message: '请输选择发布时间', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // this.getList()
  },
  watch: {
  },
  mounted() {

    this.getList()
  },
  methods: {
    // 文件上传时钩子
    imgUpload(file) {
      this.imgList = []
      let formdata = new FormData()
      formdata.append('folder', 'rotate')
      formdata.append('file', file)
      uploadFile(formdata).then(res => {
        // this.dialogImageUrl = this.web + res;
        this.imgList.push({
          url: this.web + res
        })
        this.form.picPath = res
      })
    },
    // 文件列表移除文件时的钩子
    handleRemove() {
      this.form.picPathHttpUrl = null
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogFormVisible = true
      this.dialogVisible = true
    },
    // // 图片上传返回的数据
    // handleAvatarSuccess(response) {
    //   this.form.picPath = response
    // },
    //清空表单
    formData() {
      this.form.name = null
      this.form.articleSource = null
      this.form.author = null
      this.form.content = null
      this.form.releaseTime = null
      this.form.viewsNumber = null
      this.form.id = null
      this.picPath = null
    },
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size
      return index
    },

    async getList() {
      await webServerHttpUrl().then(res => {
        this.web = res
      })
      await rotateList(this.current, this.size, this.params).then(res => {
        // this.tableData = res
        this.recordsTotal = res.total
        this.tableData = res.records
        this.tableData.forEach(item => {
          this.$nextTick(() => {
            item.picPath = this.web + item.picPath
          })
        })
      })
    },
    // 排序
    handleSort(id, upOrDown) {
      rotateSort(id, upOrDown).then(() => {
        this.getList()
      })
    },
    // 弹框关闭事件
    close(formName) {
      this.$refs[formName].resetFields()
      if (this.$refs.upload != null) {
        this.$refs.upload.clearFiles()
      }
    },
    //保存按钮
    formSave(formName) {
      console.log(this.form)
      let _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              console.log(_this.form)
              if (this.form.id == null) {
                messageInfoSave(_this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              } else {
                messageInfoupdate(_this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.formData()
                    this.getList()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              }
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //  分页
    handleCurrentChange(val) {
      this.current = val
      this.getList()
    },
    //新增
    userAddData() {
      this.imgList = []
      this.title = '新增'
      this.formData()
      this.dialogFormVisible = true
    },
    //编辑
    handleEdit(id) {
      this.imgList = []
      this.title = '编辑'
      this.formData();
      rotatePicturesDetail(id).then(res => {
        this.form = res
        this.form.id = res.id
        this.imgList.push({
          url: res.picPathHttpUrl
        })
        this.dialogFormVisible = true
      })
    },
    //删除
    handleDelete(id) {
      this.$confirm('您确定删除吗？', '信息提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          rotatePicturesDelete(id).then(res => {
            console.log(res)
            this.$alert('删除成功！', '信息提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.getList()
            })
          })
        })
        .catch(() => {})
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields()
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  margin: auto;
}

.form {
  width: 95%;
  display: inline-block;
  margin: 10px 10px;
}
/deep/.el-upload-list__item-actions {
  width: 200px;
  height: 100px;
}
.form1 {
  width: 100%;
  display: inline-block;
  margin: 13px 10px;
}

.formInput {
  width: 67%;
  /*margin-left: 16px;*/
}

/deep/ .el-dialog__body {
  margin-top: -19px;
}

/deep/ .ql-editor {
  min-height: 140px !important;
}
/deep/.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}
/deep/.el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 37px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 300px;
  height: 150px;
}
/deep/.el-upload-list__item-actions {
  width: 300px;
  height: 150px;
}

/deep/ #quill-container {
  height: 100px;
}
/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
